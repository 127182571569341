<template>
  <component-frame title="Navigation">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#sidebar'">Sidebar Navigation</a></li>
          <li><a href="#" v-scroll-to="'#page'">Page Navigation</a></li>
          <li><a href="#" v-scroll-to="'#next-prev'">Next / Previous</a></li>
          <li><a href="#" v-scroll-to="'#pagination'">Pagination</a></li>
          <li><a href="#" v-scroll-to="'#scroll-top'">Scroll to Top</a></li>
          <li><a href="#" v-scroll-to="'#drawer'">Drawer</a></li>
        </ul>
      </div>
    </div>

    <h3 id="sidebar">Sidebar Navigation</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <nav class="nav flex-column" role="navigation">
            <div class="list-group">
              <h4>Menu Title</h4>
              <a class="nav-link" href="#">Navigation link</a>
              <a class="nav-link active" href="#">Active Navigation link</a>
              <a class="nav-link" href="#">Navigation link</a>
              <a class="nav-link" href="#">Navigation link</a>
              <a class="nav-link" href="#">Navigation link</a>
            </div>
          </nav>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lg;nav class="nav flex-column" role="navigation"&gt;
  &lg;div class="list-group"&gt;
    &lg;h4&gt;Menu Title&lg;/h4&gt;
    &lg;a class="nav-link" href="#"&gt;Navigation link&lg;/a&gt;
    &lg;a class="nav-link active" href="#"&gt;Active Navigation link&lg;/a&gt;
    &lg;a class="nav-link" href="#"&gt;Navigation link&lg;/a&gt;
    &lg;a class="nav-link" href="#"&gt;Navigation link&lg;/a&gt;
    &lg;a class="nav-link" href="#"&gt;Navigation link&lg;/a&gt;
  &lg;/div&gt;
&lg;/nav&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            All menus should have a clear title describing if the menu is navigation, help links, or relevant resources
          </li>
          <li>
            Sidebar navigation should only contain links to contextual and related pages
          </li>
          <li>A menu should not contain more than 5-6 items if possible</li>
          <li>External links should open in a new tab</li>
        </ul>
      </div>
    </div>

    <h3 id="sidebar">Page Navigation</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="on-this-page">
            <div class="on-this-page-title">On This Page</div>
            <ul class="on-this-page-anchor-list">
              <li><a href="#">Why Hire a Professional?</a></li>
              <li><a href="#">Services Provided by LTSA</a></li>
              <li><a href="#">Hire a Professional to Assist You</a></li>
            </ul>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;div class="on-this-page"&gt;
      &lt;div class="on-this-page-title"&gt;On This Page&lt;/div&gt;
      &lt;ul class="on-this-page-anchor-list"&gt;
        &lt;li&gt;&lt;a href="#"&gt;Why Hire a Professional?&lt;/a&gt;&lt;/li&gt;
        &lt;li&gt;&lt;a href="#"&gt;Services Provided by LTSA&lt;/a&gt;&lt;/li&gt;
        &lt;li&gt;&lt;a href="#"&gt;Hire a Professional to Assist You&lt;/a&gt;&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            On longer pages consider adding this pattern beneath the main heading to give the user an overview of the page
          </li>
        </ul>
      </div>
    </div>

    <h3 id="next-prev">Next / Previous</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <a class="d-flex align-items-center mb-4" href="javascript:void(0)">
            <svg class="icon icon-arrow_cta_back mr-1">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use>
            </svg>
            Return to Previous Purchase
          </a>
          <a class="d-flex align-items-center" href="javascript:void(0)">
            <span
              >Continue to 02 - <br />
              Application of Charge</span
            >
            <svg class="icon icon-arrow_cta ml-1">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
            </svg>
          </a>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;a class="d-flex align-items-center" href="javascript:void(0)"&gt;
  &lt;svg class="icon icon-arrow_cta_back mr-1"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"&gt;&lt;/use&gt;&lt;/svg&gt;
  Return to Previous Purchase
&lt;/a&gt;
&lt;a class="d-flex align-items-center" href="javascript:void(0)"&gt;
  &lt;span&gt;Continue to 02 - &lt;br&gt; Application of Charge&lt;/span&gt;
  &lt;svg class="icon icon-arrow_cta ml-1"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"&gt;&lt;/use&gt;&lt;/svg&gt;
&lt;/a&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Indicates direction/progression</li>
          <li>Use when progressing through a flow and not saving</li>
        </ul>
      </div>
    </div>

    <h3 id="pagination">Pagination</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)" aria-label="First">First</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)" aria-label="First">Previous</a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="javascript:void(0)">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)" aria-label="Next">
                Next
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)" aria-label="Next">
                Last
              </a>
            </li>
          </ul>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">

                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Show 5 pages max with two previous pages before and after current page
          </li>
        </ul>
      </div>
    </div>

    <h3 id="scroll-top">Scroll to Top</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <a href="#top" class="scroll-to-top">
            <svg class="icon icon-arrow_upward">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg
            >Back to Top
          </a>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;a href="#top" class="scroll-to-top btn btn-tertiary"&gt;
  &lt;svg class="icon icon-arrow_upward"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"&gt;&lt;/use&gt;&lt;/svg&gt;
  Top
&lt;/a&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Show scroll to top button on small screens where content is more than 4 screens long
          </li>
          <li>Only show button when user has scrolled more than 3 screens</li>
          <li>
            Add enough padding below functional buttons so the button is not blocking access
          </li>
        </ul>
      </div>
    </div>

    <h3 id="drawer">Drawer</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="drawer fade" id="drawer-left">
            <div class="drawer-content left">
              <button class="close" data-dismiss="drawer" aria-label="Close">
                <span>&times;</span>
              </button>
              <h4>Drawer</h4>
              <p>This is an example of a left side drawer</p>
            </div>
          </div>
          <button
            class="btn btn-secondary"
            type="button"
            data-toggle="drawer"
            data-target="#drawer-left"
            aria-controls="drawer-left"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Toggle left drawer
          </button>

          <div class="drawer fade" id="drawer-right">
            <div class="drawer-content right">
              <button class="close" data-dismiss="drawer" aria-label="Close">
                <span>&times;</span>
              </button>
              <h4>Drawer</h4>
              <p>This is an example of a right side drawer</p>
            </div>
          </div>

          <button
            class="btn btn-secondary"
            type="button"
            data-toggle="drawer"
            data-target="#drawer-right"
            aria-controls="drawer-right"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Toggle right drawer
          </button>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
<span class="token comment">&lt;!-- Empty drawer --&gt;</span>
&lt;div class="drawer fade" id="drawer-left"&gt;
  &lt;div class="drawer-content left"&gt;
    &lt;button class="close" data-dismiss="drawer" aria-label="Close"&gt;
      &lt;span&gt;&times;&lt;/span&gt;
    &lt;/button&gt;
    <span class="token comment">&lt;!-- Drawer content --&gt;</span>
  &lt;/div&gt;
&lt;/div&gt;

<span class="token comment">&lt;!-- Left drawer --&gt;</span>
&lt;div class="drawer fade" id="drawer-left"&gt;
  &lt;div class="drawer-content left"&gt;
    &lt;button class="close" data-dismiss="drawer" aria-label="Close"&gt;
      &lt;span&gt;&times;&lt;/span&gt;
    &lt;/button&gt;
    &lt;h4&gt;Drawer&lt;/h4&gt;
    &lt;p&gt;This is an example of a left side drawer&lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;

<span class="token comment">&lt;!-- Left drawer toggle --&gt;</span>
&lt;button class="btn btn-secondary" type="button" data-toggle="drawer" data-target="#drawer-left" aria-controls="drawer-left" aria-expanded="false" aria-label="Toggle navigation"&gt;
  Toggle left drawer
&lt;/button&gt;

<span class="token comment">&lt;!-- Right drawer --&gt;</span>
&lt;div class="drawer fade" id="drawer-right"&gt;
  &lt;div class="drawer-content right"&gt;
    &lt;button class="close" data-dismiss="drawer" aria-label="Close"&gt;
      &lt;span&gt;&times;&lt;/span&gt;
    &lt;/button&gt;
    &lt;h4&gt;Drawer&lt;/h4&gt;
    &lt;p&gt;This is an example of a right side drawer&lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;

<span class="token comment">&lt;!-- Right drawer toggle --&gt;</span>
&lt;button class="btn btn-secondary" type="button" data-toggle="drawer" data-target="#drawer-right" aria-controls="drawer-right" aria-expanded="false" aria-label="Toggle navigation"&gt;
  Toggle right drawer
&lt;/button&gt;
                </code></pre>
        </div>
        <h4 class="mt-4">How it works</h4>
        <ul>
          <li>
            Any content added to a drawer will be positioned off screen and can be toggled by an element with the appropriate target attribute
          </li>
          <li>
            A drawer with class .drawer will be hidden on all screen sizes. Adding an additional class of .drawer-sm, .drawer-md, or .drawer-lg will hide the
            menu on its respective breakpoint instead
          </li>
        </ul>

        <h4 class="mt-4">Example of a persistent left sliding drawer</h4>
        <p>
          Apply a class of .left or .right to the drawer-content to set its direction
        </p>

        <pre v-highlight class="d-block"><code class="language-html">
<span class="token comment">&lt;!-- Empty drawer --&gt;</span>
&lt;div class="drawer fade" id="drawer-left"&gt;
  &lt;div class="drawer-content left"&gt;
    &lt;button class="close" data-dismiss="drawer" aria-label="Close"&gt;
      &lt;span&gt;&times;&lt;/span&gt;
    &lt;/button&gt;
    <span class="token comment">&lt;!-- Drawer content --&gt;</span>
  &lt;/div&gt;
&lt;/div&gt;

<span class="token comment">&lt;!-- Drawer toggle --&gt;</span>
&lt;button class="btn btn-secondary" type="button" data-toggle="drawer" data-target="#drawer-left" aria-controls="drawer-left" aria-expanded="false" aria-label="Toggle navigation"&gt;
  Toggle drawer
&lt;/button&gt;
                </code></pre>

        <h4 class="mt-4">Example of a drawer with a medium breakpoint</h4>
        <p>
          Any content inside the drawer will look normal on screens above the medium breakpoint and collapse below it. Apply Bootstraps visibility classes to
          the toggle element to make sure it's also only visible on the selected breakpoint
        </p>

        <pre v-highlight class="d-block"><code class="language-html">
<span class="token comment">&lt;!-- Empty drawer --&gt;</span>
&lt;div class="drawer drawer-md fade" id="drawer"&gt;
  &lt;div class="drawer-content left"&gt;
    &lt;button class="close" data-dismiss="drawer" aria-label="Close"&gt;
      &lt;span&gt;&times;&lt;/span&gt;
    &lt;/button&gt;
    <span class="token comment">&lt;!-- Drawer content --&gt;</span>
  &lt;/div&gt;
&lt;/div&gt;

<span class="token comment">&lt;!-- Drawer toggle --&gt;</span>
&lt;button class="btn btn-secondary d-md-none" type="button" data-toggle="drawer" data-target="#drawer" aria-controls="drawer" aria-expanded="false" aria-label="Toggle navigation"&gt;
  Toggle drawer
&lt;/button&gt;
                </code></pre>
        <h4 class="mt-4">Events and methods</h4>
        <p>
          The drawer component is based on Bootstrap's
          <a href="https://getbootstrap.com/docs/4.0/components/modal/" target="_blank">modal component</a>
          and has the same events and methods available but with modal replaced with drawer. So .modal('dispose') should be .drawer('dispose').
        </p>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use the drawer plugin to create off canvas menus and utilities
          </li>
          <li>
            Drawers can be persistent or used with responsive classes to create real estate on smaller screens
          </li>
          <li>Drawers should open from the same side as their toggles</li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "layout",
  data() {
    return {
      title: "Navigation",
      scrollTop: false,
    };
  },
};
</script>
